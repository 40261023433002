var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createSlice } from '@reduxjs/toolkit';
import { IStatus } from '../../../@types/status';
import { TodoStatus } from '../../../@types/Todo';
import { createNewTodo, deleteOneTodo, getOneTodo, getTodosFilteredTodos, getTodosCreatedbyMe, updateTodo, updateTodoStatusAndPriority, } from './actions';
var initialState = {
    todos: { docs: [], meta: {} },
    completedTodos: { docs: [], meta: {} },
    incompletedTodos: { docs: [], meta: {} },
    todo: {},
    mentions: { offices: [], users: [], meta: {} },
    status: IStatus.IDLE,
    statusMentions: IStatus.IDLE,
    statusUpdated: IStatus.IDLE,
};
var slice = createSlice({
    name: 'todos',
    initialState: initialState,
    reducers: {
        resetTasks: function (state) {
            state.completedTodos = { docs: [], meta: {} };
            state.incompletedTodos = { docs: [], meta: {} };
        },
    },
    extraReducers: function (builder) {
        // create
        builder
            .addCase(createNewTodo.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(createNewTodo.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            if (action.payload.data.status === TodoStatus.COMPLETED) {
                state.completedTodos.docs = __spreadArray([action.payload.data], state.completedTodos.docs, true);
            }
            else {
                state.incompletedTodos.docs = __spreadArray([action.payload.data], state.incompletedTodos.docs, true);
            }
        })
            .addCase(createNewTodo.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // get Created By me
        builder
            .addCase(getTodosCreatedbyMe.pending, function (state, action) {
            if (action.meta.arg.page === 1) {
                state.status = IStatus.LOADING;
            }
        })
            .addCase(getTodosCreatedbyMe.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            if (action.meta.arg.filterStatus === TodoStatus.COMPLETED) {
                state.completedTodos.docs = __spreadArray(__spreadArray([], state.completedTodos.docs, true), action.payload.data.docs, true);
                state.completedTodos.meta = action.payload.data.meta;
            }
            else {
                state.incompletedTodos.docs = __spreadArray(__spreadArray([], state.incompletedTodos.docs, true), action.payload.data.docs, true);
                state.incompletedTodos.meta = action.payload.data.meta;
            }
        })
            .addCase(getTodosCreatedbyMe.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // get Assigned To me
        builder
            .addCase(getTodosFilteredTodos.pending, function (state, action) {
            if (action.meta.arg.page === 1) {
                state.status = IStatus.LOADING;
            }
        })
            .addCase(getTodosFilteredTodos.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            if (action.meta.arg.filterStatus === TodoStatus.COMPLETED) {
                state.completedTodos.docs = __spreadArray(__spreadArray([], state.completedTodos.docs, true), action.payload.data.docs, true);
                state.completedTodos.meta = action.payload.data.meta;
            }
            else {
                state.incompletedTodos.docs = __spreadArray(__spreadArray([], state.incompletedTodos.docs, true), action.payload.data.docs, true);
                state.incompletedTodos.meta = action.payload.data.meta;
            }
        })
            .addCase(getTodosFilteredTodos.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // Delete one
        builder
            .addCase(deleteOneTodo.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(deleteOneTodo.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            if (action.meta.arg.status === TodoStatus.COMPLETED) {
                state.completedTodos.docs = state.completedTodos.docs.filter(function (todo) { return todo._id !== action.meta.arg.todoId; });
            }
            else {
                state.incompletedTodos.docs = state.incompletedTodos.docs.filter(function (todo) { return todo._id !== action.meta.arg.todoId; });
            }
        })
            .addCase(deleteOneTodo.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // Edit one
        builder
            .addCase(updateTodo.pending, function (state) {
            state.statusUpdated = IStatus.LOADING;
        })
            .addCase(updateTodo.fulfilled, function (state, action) {
            state.statusUpdated = IStatus.SUCCEEDED;
            if (action.payload.data.status === TodoStatus.COMPLETED) {
                state.completedTodos.docs = state.completedTodos.docs.map(function (todo) {
                    return todo._id !== action.meta.arg.todoId ? todo : action.payload.data;
                });
            }
            else {
                state.incompletedTodos.docs = state.incompletedTodos.docs.map(function (todo) {
                    return todo._id !== action.meta.arg.todoId ? todo : action.payload.data;
                });
            }
        })
            .addCase(updateTodo.rejected, function (state) {
            state.statusUpdated = IStatus.FAILED;
        });
        // Edit STATUS PRIORITY
        builder
            .addCase(updateTodoStatusAndPriority.pending, function (state) {
            state.statusUpdated = IStatus.LOADING;
        })
            .addCase(updateTodoStatusAndPriority.fulfilled, function (state, action) {
            var _a, _b;
            state.statusUpdated = IStatus.SUCCEEDED;
            var isCompleted = ((_a = action.payload.data) === null || _a === void 0 ? void 0 : _a.status) === TodoStatus.COMPLETED;
            if ((_b = action.meta.arg.body) === null || _b === void 0 ? void 0 : _b.priority) {
                if (isCompleted) {
                    state.completedTodos.docs = state.completedTodos.docs.map(function (task) {
                        return task._id === action.payload.data._id ? action.payload.data : task;
                    });
                }
                else {
                    state.incompletedTodos.docs = state.incompletedTodos.docs.map(function (task) {
                        return task._id === action.payload.data._id ? action.payload.data : task;
                    });
                }
            }
            else if (isCompleted) {
                state.completedTodos.docs = __spreadArray([action.payload.data], state.completedTodos.docs, true);
                state.incompletedTodos.docs = state.incompletedTodos.docs.filter(function (task) { return task._id !== action.payload.data._id; });
            }
            else {
                state.incompletedTodos.docs = __spreadArray([action.payload.data], state.incompletedTodos.docs, true);
                state.completedTodos.docs = state.completedTodos.docs.filter(function (task) { return task._id !== action.payload.data._id; });
            }
            state.todos.docs = state.todos.docs.map(function (todo) {
                return todo._id !== action.meta.arg.todoId ? todo : action.payload.data;
            });
        })
            .addCase(updateTodoStatusAndPriority.rejected, function (state) {
            state.statusUpdated = IStatus.FAILED;
        });
        // GET ONE
        builder
            .addCase(getOneTodo.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getOneTodo.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.todo = action.payload.data;
        })
            .addCase(getOneTodo.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
    },
});
export var resetTasks = slice.actions.resetTasks;
export default slice.reducer;
